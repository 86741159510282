import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { handleMobileSearchForm, handleMenu } from './actions/index';

import { createBrowserHistory } from 'history';
import asyncComponent from './AsyncComponent'
import GoogleMapScript from './components/googleMapScript';

/*
import HomePage from './views/homePage';
import CategoryPage from './views/categoryPage';
import DetailPage from './views/detailPage';
import LoginPage from './views/loginPage';
import RegisterPage from './views/registerPage';
import ChangePasswordPage from './views/changePasswordPage';
import NewsletterPage from './views/newsletterPage';

import AccountPage from './views/accountPage';
import CartPage from './views/cartPage';
import AccountDataPage from './views/accountDataPage';
import AccountAddressPage from './views/accountAddressPage';
import WishlistPage from './views/wishlistPage';
import OrdersPage from './views/ordersPage';
import OrderDonePage from './views/orderDonePage';
import OrderCancelPage from './views/orderCancelPage';
import AgbPage from './views/agbPage';
import WithdrawlPage from './views/withdrawlPage';
import PrivacyPolicyPage from './views/privacyPolicyPage';

import ImprintPage from './views/imprintPage';
import ForgetPasswordPage from './views/forgetPasswordPage';
import ResetPasswordPage from './views/resetPasswordPage';
import ContactPage from './views/contactPage';
import ConfirmMailPage from './views/confirmMailPage';
import ErrorPage from './views/errorPage';
import AboutPage from './views/aboutPage';
import NewsDetailPage from './views/newsDetailPage';
import NewsPage from './views/newsPage';
*/

const HomePage = asyncComponent(() =>
    import('./views/homePage').then(module => module.default)
)
const CategoryPage = asyncComponent(() =>
    import('./views/categoryPage').then(module => module.default)
)
const DetailPage = asyncComponent(() =>
    import('./views/detailPage').then(module => module.default)
)
const LoginPage = asyncComponent(() =>
    import('./views/loginPage').then(module => module.default)
)
const RegisterPage = asyncComponent(() =>
    import('./views/registerPage').then(module => module.default)
)
const ChangePasswordPage = asyncComponent(() =>
    import('./views/changePasswordPage').then(module => module.default)
)
const NewsletterPage = asyncComponent(() =>
    import('./views/newsletterPage').then(module => module.default)
)


const AccountPage = asyncComponent(() =>
    import('./views/accountPage').then(module => module.default)
)
const CartPage = asyncComponent(() =>
    import('./views/cartPage').then(module => module.default)
)
const AccountDataPage = asyncComponent(() =>
    import('./views/accountDataPage').then(module => module.default)
)
const AccountAddressPage = asyncComponent(() =>
    import('./views/accountAddressPage').then(module => module.default)
)
const WishlistPage = asyncComponent(() =>
    import('./views/wishlistPage').then(module => module.default)
)
const OrdersPage = asyncComponent(() =>
    import('./views/ordersPage').then(module => module.default)
)
const PrivacyPolicyPage = asyncComponent(() =>
    import('./views/privacyPolicyPage').then(module => module.default)
)


const ForgetPasswordPage = asyncComponent(() =>
    import('./views/forgetPasswordPage').then(module => module.default)
)
const ResetPasswordPage = asyncComponent(() =>
    import('./views/resetPasswordPage').then(module => module.default)
)
const ContactPage = asyncComponent(() =>
    import('./views/contactPage').then(module => module.default)
)
const ConfirmMailPage = asyncComponent(() =>
    import('./views/confirmMailPage').then(module => module.default)
)
const ErrorPage = asyncComponent(() =>
    import('./views/errorPage').then(module => module.default)
)

const NewsPage = asyncComponent(() =>
    import('./views/newsPage').then(module => module.default)
)

const NewsDetailPage = asyncComponent(() =>
    import('./views/newsDetailPage').then(module => module.default)
)


const history = createBrowserHistory();

class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        const unlisten = history.listen((location, action) => {
            //this.props.handleMenu(null);
            this.props.handleMenu(null);
            this.props.handleChange(null);
            window.scrollTo(0, 0);
        });
    }

    render() {
        return (
            <Router history={history} onUpdate={() => window.scrollTo(0, 0)} >
                <div>
                    <GoogleMapScript API_KEY={'AIzaSyDx7uNRz2GYWKLlAlfT6wugFOSBXQ7EZaQ'} />

                    <Switch>

                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/popularno"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/akcija"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/novosti"
                            exact
                            render={(...renderProps) => (
                                <NewsPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/novosti/:id"
                            exact
                            render={(...renderProps) => (
                                <NewsDetailPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/category"

                            render={(...renderProps) => (
                                <CategoryPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/product/:alias/:id"
                            exact
                            render={(...renderProps) => (
                                <DetailPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/account/change-password"
                            exact
                            render={(...renderProps) => (
                                <ChangePasswordPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/register"
                            exact
                            render={(...renderProps) => (
                                <RegisterPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/account/newsletter"
                            exact
                            render={(...renderProps) => (
                                <NewsletterPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account"
                            exact
                            render={(...renderProps) => (
                                <AccountPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/cart"
                            exact
                            render={(...renderProps) => (
                                <CartPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/cart/:paypalReturnInfo"
                            exact
                            render={(...renderProps) => (
                                <CartPage {...renderProps} {...this.props} />
                            )}
                        />



                        <Route
                            path="/account/edit"
                            exact
                            render={(...renderProps) => (
                                <AccountDataPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account/address"
                            exact
                            render={(...renderProps) => (
                                <AccountAddressPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account/wishlist"
                            exact
                            render={(...renderProps) => (
                                <WishlistPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/account/orders"
                            exact
                            render={(...renderProps) => (
                                <OrdersPage {...renderProps} {...this.props} />
                            )}
                        />



                        <Route
                            path="/page/:alias"
                            exact
                            render={(...renderProps) => (
                                <PrivacyPolicyPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/forget-password"
                            exact
                            render={(...renderProps) => (
                                <ForgetPasswordPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/reset-password/:uid/:code"
                            exact
                            render={(...renderProps) => (
                                <ResetPasswordPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/contact"
                            exact
                            render={(...renderProps) => (
                                <ContactPage {...renderProps} {...this.props} />
                            )}
                        />


                        <Route
                            path="/confirm-email/:uid/:code"
                            exact
                            render={(...renderProps) => (
                                <ConfirmMailPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route

                            render={(...renderProps) => (
                                <ErrorPage {...renderProps} {...this.props} />
                            )}
                        />
                    </Switch>
                </div>
            </Router >
        );
    }
}



const mapStateToProps = state => ({
    searchForm: state.searchForm,
    menu: state.menu
});


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleChange: (val) => {
            dispatch(handleMobileSearchForm(val))
        },
        handleMenu: (val) => {
            dispatch(handleMenu(val))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Routes);
