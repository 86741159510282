

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';
import popup_bg from './assets/images/popupbg.png';
import coupon from './assets/images/coupon.png';
import logo from './assets/images/logo.png';

import image from './assets/images/no-image.jpg';
import Isvg from 'react-inlinesvg';
import close_icon from './assets/svg/close-ico.svg';
import whatsapp from './assets/images/whatsapp.png';
import viber from './assets/images/viber.png';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

Number.prototype.formatPrice = function (currency) {
  let price = this;
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}

String.prototype.formatPrice = function (currency) {
  let price = parseFloat(this);
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}


class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    window.googleMapsCallback = this.googleMapsCallback;
    this.setCurrency = this.setCurrency.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.addToCart1 = this.addToCart1.bind(this);
    this.handleLeftMenu = this.handleLeftMenu.bind(this);
    this.state = {  
      lang: 'de',
      breadcrumb: [],
      categories: [],
      uData: null,
      cartCount: 0,
      newestProducts: [],
      popularProducts: [],
      specialOfferProducts: [],
      offerProducts: [],
      promotedProducts: [],
      news: [],
      infoMessages: {

      },
      currency: {
        code: '€',
        exchangeRate: 1
      },
      pages: {
        'kako-naruciti': {},
        'privacy-policy': {},
        'terms-and-conditions': {},
        'o-nama': {}
      }
    }
  }

  handleLeftMenu(val){
    console.log("handleMenu", val);
    this.setState({
      menuState: val
    })
  }

  addToCart(product) {
    let haveVariations = false;
    if (product && product.package) {
      for (let i = 0; i < product.package.length; i++) {
        if (product.package[i].name) {
          haveVariations = true;
          break;
        }
      }
    }



    if (!haveVariations) {
      if (window.location.pathname.indexOf('/product') == -1) {
        this.state.socketIOClient.once('addToCart', (data) => {
          console.log(data);
          if (data.successful) {
            this.showInfoMessage(this.translate('Artikal je uspešno dodat u korpu.'));
            this.state.socketIOClient.emit('cartInfo', {});
          } else {
            this.showInfoMessage(this.translate('Nema dovoljno na stanju.'), true);

          }
        });
      }


      this.state.socketIOClient.emit('addToCart', { _id: product._id, quantity: 1, package: product.package[0] });
    } else {
      this.setState({
        addToCartModal: product,
        package: product.package[0],
        quantity: 1
      })
    }



  }

  addToCart1(product) {

      if (window.location.pathname.indexOf('/product') == -1) {
        this.state.socketIOClient.once('addToCart', (data) => {
          console.log(data);
          if (data.successful) {
            this.showInfoMessage(this.translate('Artikal je uspešno dodat u korpu.'));
            this.state.socketIOClient.emit('cartInfo', {});
          } else {
            this.showInfoMessage(this.translate('Nema dovoljno na stanju.'), true);

          }
        });
      }


      this.state.socketIOClient.emit('addToCart', { _id: this.state.addToCartModal._id, quantity: this.state.quantity, package: this.state.package });
      this.setState({
        addToCartModal: null
      })
      
  }


  setCurrency(currency) {
    this.setState({
      currency
    })
  }

  showInfoMessage(text, error) {
    console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  hidePopup() {
    localStorage.popup = Math.floor(Date.now() / 1000);
    console.log(true)
    this.setState({
      popup: null
    })
  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  componentDidMount() {

    if (localStorage.popup) {
      if (parseInt(localStorage.popup) + 24 * 60 * 60 < Math.floor(Date.now() / 1000)) {
        //alert(true);
        this.setState({
          popup: true
        })
      }
    } else {
      this.setState({
        popup: true
      })
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    let socket = socketIO('https://socket.auto-oprema.me');
    //let socket = socketIO('192.168.0.178:4001');

    //let socket = socketIO('http://35.198.145.129:4001');
    console.log(socket);
    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        socket.emit("userVerify", { email: uData.User.EMail, pk: uData.User.pk });
    }


    socket.on('cartInfo', (data) => {
      this.setState({
        cartCount: data.count
      })
    })

    socket.on('userVerify', (data) => {
      console.log(data);
      if (data.successful) {
        this.setUserData(data.user);
      }

      socket.emit('cartInfo', {});

    });


    socket.on('fetchSpecialOfferProducts', (data) => {
      this.setState({
        specialOfferProducts: data
      });
    })


    socket.on('fetchOfferProducts', (data) => {
      console.log(data);
      this.setState({
        offerProducts: data
      });
    })

    socket.on('fetchPopularProducts', (data) => {
      this.setState({
        popularProducts: data
      })
    });
    socket.on('fetchPromotedProducts', (data) => {
      this.setState({
        promotedProducts: data
      })
    });

    socket.on('fetchNewestProducts', (data) => {
      this.setState({
        newestProducts: data
      })
    });


    socket.on('fetchCategories', (data) => {
      console.log(data);
      this.setState({
        categories: data
      });
    });

    socket.on('fetchNews', (data) => {
      console.log(data);
      this.setState({
        news: data
      });
    });


    socket.on('fetchPopup', (data) => {
      console.log(data);
      this.setState({
        popupData: data
      });
    });
    socket.on('fetchPages', (data) => {
      console.log(data);
      this.setState({
        pages: data
      });
    });



    socket.on('userLogout', (data) => {
      socket.emit('cartInfo', {});
    })

    socket.emit('fetchCategories', {});
    socket.emit('fetchNewestProducts', {});
    socket.emit('fetchPopularProducts', {});
    socket.emit('fetchPromotedProducts', {});
    socket.emit('fetchOfferProducts', {});

    socket.emit('fetchNews', {});
    socket.emit('fetchPages', {});
    socket.emit('fetchSpecialOfferProducts', {});

    socket.on('siteData', (data) => {
      console.log(data);
      this.setState({
        siteData: data
      });
    });


  }


  translate(text) {
    return text;
  }

  render() {
    let product = this.state.addToCartModal;


    return (
      <div>
        <Routes
          translate={this.translate}
          updateBreadcrumb={this.updateBreadcrumb}
          setUserData={this.setUserData}
          allowCookies={this.allowCookies}
          showInfoMessage={this.showInfoMessage}
          hideInfoMessage={this.hideInfoMessage}
          hidePopup={this.hidePopup}
          setCurrency={this.setCurrency}
          addToCart={this.addToCart}
          handleLeftMenu={this.handleLeftMenu}
          {...this.state}
        />
        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <i className="mdi mdi-close hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="mdi mdi-close" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

        {this.state.popup && this.state.popupData ?
          <div className="popup">
            <img src={this.state.popupData.Background} className="overlay" />
            <div className="content">
              <img src={this.state.popupData.Image} className="coupon" />
              <h2>{this.state.popupData.Title}</h2>
              <h3>{this.state.popupData.Subtitle}</h3>
              <h6>{this.state.popupData.Text}</h6>
              <button className="button" onClick={() => this.hidePopup()}>{this.translate('BEGINN MIT DEM EINKAUF')}</button>
            </div>
          </div>
          : null
        }
        {
          product ?
            <div className="modal-overlay">
              <div>
                <Container className="article-box article-detail">
                  <button className="close-button" onClick={() => this.setState({ addToCartModal: null })}><Isvg src={close_icon} /></button>
                  <Row>
                    <Col lg="5" className="article-image">
                      <img className="preview" onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? product.Images ? product.Images[0] : image : image} />

                    </Col>


                    <Col lg="7">
                      <h3>Šifra: {product.Alias} {product.StockLevel > 0 ?
                        <p className="stock"> <div className="in-stock"></div> Na stanju</p>
                        :
                        <p className="stock"> <div className="out-of-stock"></div> Nema na stanju</p>
                      }</h3>

                      <h2>{product.Name && product.Name}  </h2>


                      <div className="spacer"></div>


                      <div className="price">
                        {this.state.package && this.state.package.name}
                        <span>{this.state.package && this.state.package.price && this.state.package.price.formatPrice(this.state.currency)}</span>

                      </div>

                      <div className="packages">
                        {
                          product.package && product.package.map((item, idx) => {
                            return (
                              <div key={idx} onClick={() => this.setState({ package: item })} className={this.state.package && this.state.package.name == item.name ? 'package active' : 'package'}>
                                <p>{item.name}</p>
                                <p>{item.price && item.price.formatPrice(this.state.currency)}</p>

                              </div>
                            )
                          })
                        }
                      </div>

                      <div className="spacer"></div>
                      {product.StockLevel > 0 && this.state.package && this.state.package.price ?
                        <div className="actions">
                          <div>
                            <div className="quantity-input">
                              <span>Količina</span>
                              <div>
                                <button className="dec" onClick={() => {
                                  if (this.state.quantity - 1 < product.MinOrder) {
                                    return;
                                  }
                                  this.setState({
                                    quantity: this.state.quantity - 1
                                  })
                                }}></button>
                                <input type="text" value={this.state.quantity} onChange={(e) => this.setState({ quantity: e.target.value })} />
                                <button className="inc" onClick={() => {
                                  this.setState({
                                    quantity: this.state.quantity + 1
                                  })
                                }}></button>

                              </div>
                            </div>
                            <button className="button button-black" onClick={this.addToCart1}>{this.translate('Dodaj u korpu')}</button>

                          </div>
                          <div>

                          </div>
                        </div>
                        : null}

                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            :

            null
        }
        <a href="https://api.whatsapp.com/send?phone=+38267575111" className="whatsapp"><img src={whatsapp} /></a>
        <a href="viber://contact?number=%2B38267544623" className="viber"><img src={viber} /></a>


        <div className="loader">
          <img src={logo} />
        </div>

      </div>

    );

  }

}

export default App;
